import cn from 'classnames';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { IValidateClip } from '../../../pages/Navbar/Entity/Clip/CreateClip/ui/helpers';
import { generateUniqNameFile, getTheAudioDuration } from '../../../utils';
import { ReactComponent as Repeat } from '../assets/repeat.svg';
import { ClipAudioFile } from '../ClipAudioFile';
import { ClipUploadButton } from '../ClipUploadButton';
import { IProps, S3Client, UploadConstants } from '../utils';

import style from './ClipUploader.module.scss';

export const ClipUploader = ({
  className,
  setIsLoading,
  fileName,
  status,
  setFile,
  isClipPage,
}: IProps): JSX.Element => {
  const { setFieldValue } = useFormikContext();
  const { errors } = useFormikContext<IValidateClip>();

  const filePicker = useRef(null);
  const [duration, setDuration] = useState(0);
  const [uploadData, setUploadData] = useState(null);
  const [currentFile, setCurrentFile] = useState<File>();
  const [percentage, setPercentage] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isGettingClip, setIsGettingClip] = useState(true);
  const [headParams, setHeadParams] = useState({ Key: '', Bucket: '' });
  const [isSize, setIsSize] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const isEdit = useLocation().pathname.includes(UploadConstants.Edit);

  const pickHandle = () => {
    if (percentage > 0 && percentage <= 100) {
      deleteCurrentFileHandle();
      setCancelLoading(true);

      return;
    }
    if (filePicker.current) {
      filePicker.current.click();
    }
  };

  useEffect(() => {
    if (
      (status && status === UploadConstants.Failed) ||
      (!fileName && isEdit)
    ) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [status, fileName, isEdit]);

  const uploadAudioFileInBucket = async (file: File) => {
    try {
      setIsError(false);
      setIsLoading(true);
      const paramsUpload = {
        Bucket: `${process.env.REACT_APP_TMP_HOT_STORAGE_BUCKET}/${process.env.REACT_APP_TMP_STORAGE_AUDIO_FOLDER}`,
        ACL: 'private',
        ContentType: file.type,
        ContentDisposition: 'inline',
      };
      const ext = file.name.split('.').at(-1);
      const key = `${generateUniqNameFile(file.name)}.${ext}`;
      const uploadData = (await S3Client.upload({
        ...paramsUpload,
        Key: key,
        Body: file,
      })
        .on('httpUploadProgress', (evt) => {
          const uploaded = Math.round((evt.loaded / evt.total) * 100);
          setPercentage(uploaded);
        })
        .promise()) as {
        Key: string;
        key: string;
        Bucket: string;
        Location: string;
        ETag: string;
      };
      const keyUpload = uploadData?.Key?.split('/')[1];
      setHeadParams({ Key: uploadData?.Key, Bucket: uploadData?.Bucket });

      return { ...uploadData, key: keyUpload, Key: keyUpload };
    } catch (e) {
      setIsLoading(false);
      setIsError(true);
    }
  };

  const changeHandle = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setIsError(false);
      setCancelLoading(false);
      const [file] = event.target.files;
      setCurrentFile(file);
      setFile(file);
      setUploadData(await uploadAudioFileInBucket(file));
      setDuration(await getTheAudioDuration(file));
    } catch (e) {
      setIsLoading(false);
      setIsError(true);
    }
  };

  const sizeCheck = useCallback(() => {
    S3Client.headObject(headParams, (err, data) => {
      if (err) {
        setTimeout(() => sizeCheck(), 5000);
      } else {
        data.ContentLength > 0
          ? setIsSize(true)
          : setTimeout(() => sizeCheck(), 5000);
      }
    });
  }, [headParams]);

  useEffect(() => {
    if (duration === 0 || !uploadData || currentFile?.size === 0) {
      return;
    }
    const slicedName = currentFile?.name.split('.').slice(0, -1).join('.');

    setFieldValue('payload.fields.tmp_key', uploadData?.Key);
    setFieldValue('custom_preview.tmpKey', uploadData?.Key);
    setFieldValue('custom_preview.name', slicedName);

    if (isSize) {
      setIsLoading(false);
    } else {
      sizeCheck();
    }
  }, [
    uploadData,
    duration,
    currentFile?.size,
    setFieldValue,
    setIsLoading,
    isSize,
    sizeCheck,
    currentFile?.name,
  ]);

  const deleteCurrentFileHandle = () => {
    filePicker.current.value = '';
    setCurrentFile(null);
    setFile(null);
    setDuration(0);
    setUploadData(null);
    setPercentage(0);
    setFieldValue('payload.fields.tmp_key', '');
    setFieldValue('custom_preview', null);
    isEdit && setIsGettingClip(false);
  };

  const currentfileName = currentFile?.name || fileName;

  const shortFileName = currentfileName
    ? currentfileName?.length > 38
      ? currentfileName.slice(0, 38)
      : currentfileName
    : '...';

  return (
    <div className={cn(style.containerAudio, className)}>
      <span
        className={cn(style.titleAudio, { [style.errorTitle]: errors.audio })}
      >
        {isClipPage ? 'Аудиофайл' : 'Фрагмент'}:{uploadData ? '' : null}
      </span>

      {(uploadData && !isError) || (isEdit && isGettingClip) ? (
        <div className={style.wrapperAudio}>
          {!cancelLoading ? (
            <ClipAudioFile
              deleteCurrentFileHandle={deleteCurrentFileHandle}
              shortFileName={shortFileName}
            />
          ) : (
            <ClipUploadButton
              currentFile={currentFile}
              isError={isError}
              percentage={percentage}
              pickHandle={pickHandle}
            />
          )}
        </div>
      ) : (
        <ClipUploadButton
          currentFile={currentFile}
          isError={isError}
          percentage={percentage}
          pickHandle={pickHandle}
        />
      )}
      <input
        ref={filePicker}
        type="file"
        accept="audio/mpeg"
        className={style.hidden}
        onChange={changeHandle}
      />
      {isError && (
        <button type="button" onClick={pickHandle} className={style.repeat}>
          Повторить
          <Repeat />
        </button>
      )}
    </div>
  );
};
