import { Value } from 'aws-sdk/clients/s3';
import cn from 'classnames';
import { FieldInputProps } from 'formik';
import { ReactNode } from 'react';
import InputMask from 'react-input-mask';

import { ObligatoryField } from 'widgets/ObligatoryField';

import style from './Input.module.scss';

export const Input = ({
  field,
  label,
  placeholder,
  mask,
  isLabel = true,
  disabled = false,
  isError = false,
  className,
  isObligatory = false,
  elementRight,
  type = 'text',
}: {
  field?: FieldInputProps<Value>;
  label?: string;
  placeholder?: string;
  mask?: string;
  isLabel?: boolean;
  disabled?: boolean;
  isError?: boolean;
  className?: string;
  isObligatory?: boolean;
  elementRight?: ReactNode;
  type?: 'text' | 'number';
}): JSX.Element => (
  <label className={cn(style.container, className)} data-testid="label">
    {isLabel && (
      <h3
        className={cn(style.label, {
          [style.errorLabel]: isError,
          [style.disabledLabel]: disabled,
        })}
      >
        {label ? label : `Название`} {isObligatory && <ObligatoryField />}
      </h3>
    )}
    {mask ? (
      <InputMask
        mask={mask}
        className={cn(
          style.input,
          { [style.errorInput]: isError },
          { [style.inputWithElementRight]: !!elementRight }
        )}
        {...field}
        placeholder={placeholder ? placeholder : 'Введите название'}
        data-testid="input"
        value={field.value ? field.value : ''}
        onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()}
      />
    ) : (
      <>
        <input
          type={type}
          className={cn(
            style.input,
            { [style.errorInput]: isError },
            { [style.inputWithElementRight]: !!elementRight }
          )}
          {...field}
          disabled={disabled}
          placeholder={placeholder ? placeholder : 'Введите название'}
          data-testid="input"
          value={field.value ? field.value : ''}
          onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()}
        />
      </>
    )}
    {elementRight && <div className={style.elementRight}>{elementRight}</div>}
  </label>
);
