import { S3 } from 'aws-sdk';

export const config = {
  accessKeyId: process.env.REACT_APP_S3_PRIMARY_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_PRIMARY_SECRET_KEY,
  endpoint: process.env.REACT_APP_S3_ENDPOINT_URL,
};

export const S3Client = new S3(config);

export interface IProps {
  className?: string;
  setIsLoading?: (e: boolean) => void;
  fileName: string;
  status: string;
  setFile: (file: null | File) => void;
  isClipPage: boolean;
}

export enum UploadConstants {
  Failed = 'failed',
  Edit = 'edit',
}
